'use client';

import { Transition } from '@headlessui/react';
import { ArrowUpIcon } from 'lucide-react';

import { useWindowScroll } from '@/hooks/use-window-scroll';

import { Affix } from '../affix';
import { IconButton } from '../icon-button';

export const BackToTop: React.FC = () => {
  const [position, scrollTo] = useWindowScroll();

  return (
    <Affix className="bottom-6 right-4">
      <Transition
        show={position.y > 0}
        enter="transition-[opacity,transform] duration-250 origin-bottom"
        enterFrom="opacity-0 translate-y-[100%]"
        enterTo="opacity-100 translate-y-0"
        leave="transition-[opacity,transform] duration-250 origin-top"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-[100%]"
      >
        <IconButton
          size="xl"
          variant="solid"
          onClick={() => scrollTo({ y: 0 })}
          className="rounded-full"
        >
          <ArrowUpIcon />
        </IconButton>
      </Transition>
    </Affix>
  );
};
