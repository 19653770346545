import { useEffect } from 'react';

/**
 * Adds event listener to window on component mount and removes it on unmount.
 *
 * @param type - The event type.
 * @param listener - The event listener.
 * @param options - The event listener options.
 */
export function useWindowEvent<K extends string>(
  type: K,
  listener: K extends keyof WindowEventMap
    ? (this: Window, ev: WindowEventMap[K]) => void
    : (this: Window, ev: CustomEvent) => void,
  options?: boolean | AddEventListenerOptions,
) {
  useEffect(() => {
    // @ts-expect-error - Type can be custom event.
    window.addEventListener(type, listener, options);
    return () => {
      // @ts-expect-error - Type can be custom event.
      window.removeEventListener(type, listener, options);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, listener]);
}
