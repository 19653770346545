import { Portal, type PortalProps } from './Portal';

export interface OptionalPortalProps extends PortalProps {
  portal?: boolean;
}

export const OptionalPortal: React.FC<OptionalPortalProps> = ({
  portal = true,
  children,
  ...restProps
}) => {
  return portal ? <Portal {...restProps}>{children}</Portal> : <>{children}</>;
};

OptionalPortal.displayName = 'OptionalPortal';
