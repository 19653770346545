import { useEffect, useState } from 'react';

import { useWindowEvent } from './use-window-event';

export interface ScrollPosition {
  x: number;
  y: number;
}

/**
 * Get the current scroll position of the window.
 */
export function getScrollPosition(): ScrollPosition {
  return typeof window === 'undefined' ? { x: 0, y: 0 } : { x: window.scrollX, y: window.scrollY };
}

/**
 * Scroll to a position.
 *
 * @param position - The position to scroll to.
 */
export function scrollTo(position: Partial<ScrollPosition>) {
  if (typeof window !== 'undefined') {
    const { x, y } = position;
    const scrollOptions: ScrollToOptions = { behavior: 'smooth' };

    if (typeof x === 'number') {
      scrollOptions.left = x;
    }

    if (typeof y === 'number') {
      scrollOptions.top = y;
    }

    window.scrollTo(scrollOptions);
  }
}

/**
 * Subscribe to window scroll and scroll smoothly to given position.
 *
 */
export function useWindowScroll() {
  const [position, setPosition] = useState<ScrollPosition>({ x: 0, y: 0 });

  useWindowEvent('scroll', () => setPosition(getScrollPosition()));
  useWindowEvent('resize', () => setPosition(getScrollPosition()));

  useEffect(() => {
    setPosition(getScrollPosition());
  }, []);

  return [position, scrollTo] as const;
}
