'use client';

import { useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { useIsomorphicEffect } from '@/hooks/use-isomorphic-effect';

export interface PortalProps {
  children: React.ReactNode;
  container?: HTMLElement | string;
}

export const Portal: React.FC<PortalProps> = ({ container, children }) => {
  const ref = useRef<HTMLElement | null>(null);
  const [mounted, setMounted] = useState(false);

  useIsomorphicEffect(() => {
    setMounted(true);

    if (container) {
      ref.current = typeof container === 'string' ? document.querySelector(container) : container;
    } else {
      ref.current = document.createElement('div');
      document.body.append(ref.current);
    }

    return () => {
      if (!container) {
        ref.current?.remove();
      }
    };
  }, [container]);

  if (!mounted) {
    return null;
  }

  return createPortal(children, ref.current!);
};

Portal.displayName = 'Portal';
