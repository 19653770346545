'use client';

import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { OptionalPortal } from '../portal';

export interface AffixProps extends React.HTMLAttributes<HTMLDivElement> {
  portal?: boolean;
  container?: HTMLElement;
  hidden?: boolean;
}

export const Affix = forwardRef<HTMLDivElement, AffixProps>(
  ({ portal = true, container, hidden, className, children, ...restProps }, ref) => {
    if (hidden) {
      return null;
    }

    return (
      <OptionalPortal portal={portal} container={container}>
        <div
          ref={ref}
          data-cy="affix"
          className={twMerge('fixed bottom-0 right-0 z-20', className)}
          {...restProps}
        >
          {children}
        </div>
      </OptionalPortal>
    );
  },
);

Affix.displayName = 'Affix';
