import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

export type BadgeProps = React.HTMLAttributes<HTMLSpanElement>;

export const Badge = forwardRef<HTMLSpanElement, BadgeProps>(
  ({ hidden, className, children, ...restProps }, ref) => {
    if (hidden || !children) {
      return null;
    }

    return (
      <span
        ref={ref}
        className={twMerge(
          'inline-flex h-6 shrink-0 items-center justify-center gap-x-1.5 rounded-[5px] border border-grey-mid bg-grey-light px-1.5 align-middle text-xs leading-none text-grey-dark',
          className,
        )}
        {...restProps}
      >
        {children}
      </span>
    );
  },
);

Badge.displayName = 'Tag';
