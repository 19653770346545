import { cva, type VariantProps } from 'class-variance-authority';
import Link from 'next/link';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const unstyledButtonVariants = cva(
  'relative inline-flex appearance-none bg-transparent text-black no-underline outline-none disabled:cursor-not-allowed [&>svg]:shrink-0',
  {
    variants: {
      full: {
        true: 'flex w-full',
      },
      disabled: {
        true: 'cursor-not-allowed',
      },
    },
  },
);

type ButtonAttributes = React.ButtonHTMLAttributes<HTMLButtonElement>;
type AnchorAttributes = React.ComponentPropsWithRef<typeof Link>;

interface UnstyledNativeButtonProps
  extends VariantProps<typeof unstyledButtonVariants>,
    Omit<ButtonAttributes, 'disabled'> {
  as?: 'button';
}

interface UnstyledLinkButtonProps
  extends VariantProps<typeof unstyledButtonVariants>,
    AnchorAttributes {
  as: 'a';
}

export type UnstyledButtonProps = UnstyledNativeButtonProps | UnstyledLinkButtonProps;

export const UnstyledButton = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  UnstyledButtonProps
>(
  (
    {
      as = 'button',

      full,
      disabled,

      hidden,
      className,
      children,
      ...props
    },
    ref,
  ) => {
    if (hidden) {
      return null;
    }

    const mergedClassName = twMerge(
      unstyledButtonVariants({
        full,
        disabled: as === 'button' ? null : disabled,
        className,
      }),
    );

    if (as === 'a') {
      const { href, ...restProps } = props as AnchorAttributes;

      if (disabled || !href) {
        return (
          <a ref={ref as React.Ref<HTMLAnchorElement>} className={mergedClassName} {...restProps}>
            {children}
          </a>
        );
      }

      return (
        <Link
          ref={ref as React.Ref<HTMLAnchorElement>}
          href={href}
          className={mergedClassName}
          {...restProps}
        >
          {children}
        </Link>
      );
    }

    const { type = 'button', ...restProps } = props as ButtonAttributes;

    return (
      <button
        ref={ref as React.Ref<HTMLButtonElement>}
        type={type}
        disabled={Boolean(disabled)}
        className={mergedClassName}
        {...restProps}
      >
        {children}
      </button>
    );
  },
);

UnstyledButton.displayName = 'UnstyledButton';
