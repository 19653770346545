import axios, { AxiosError } from 'axios';

import { env } from '@/env.mjs';

const publicApis = new Set(['/sign-in']);
const publicPages = new Set(['/sign-in']);

/**
 * Check if the API is public.
 *
 * @param url - API url
 * @returns True if the API is public
 */
export const isPublicApi = (url?: string) => {
  if (!url) return true;
  return publicApis.has(url);
};

/**
 * Check if the page is public.
 *
 * @param url - Page route
 * @returns True if the page is public
 */
export const isPublicPage = (url?: string) => {
  if (!url) return true;
  return publicPages.has(url);
};

const timeout = env.NEXT_PUBLIC_API_TIMEOUT;

export const request = axios.create({
  baseURL: env.NEXT_PUBLIC_API_BASE_URL,
  timeout,
  timeoutErrorMessage: `Request timeout in ${Math.round(timeout / 1000)} seconds.`,
  headers: {
    'Content-Type': 'application/json',
  },
});

request.interceptors.request.use(
  (config) => {
    if (!config.url) {
      throw new Error('Request url is required.');
    }

    if (isPublicApi(config.url)) {
      return config;
    }

    const accessToken = globalThis.sessionStorage.getItem('access_token');

    if (!accessToken) {
      throw new Error('Access token is required for protected APIs');
    }

    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    if (error instanceof Error) {
      return Promise.reject(error);
    }
    return Promise.reject(new Error('Unknown request error.'));
  },
);

request.interceptors.response.use(
  (response) => response,
  async (error: AxiosError<{ message?: string }>) => {
    switch (error.response?.status) {
      case 403: {
        throw new Error('Forbidden.');
      }
      case 404: {
        throw new Error('Not found.');
      }
      case 500:
      case 501:
      case 502:
      case 503:
      case 504: {
        throw new Error('Internal server error.');
      }
      default: {
        throw 'response' in error
          ? new Error(error.response?.data.message ?? 'Unknown response error.')
          : new Error(error instanceof AxiosError ? error.message : 'Unknown request error.');
      }
    }
  },
);
